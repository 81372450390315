import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { Maintenance, MaintenanceWindow } from './maintenance';
import { MaintenanceService } from './maintenance.service';
import * as moment from 'moment';
import { Solution, Account } from '@aveva/connect-web-core';
import { AccountService } from '../shared/account.service';
import { SolutionsService } from '../shared/solutions.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  private _accountFilter = '';
  private errorMessageSubject = new Subject<string>();
  errorMessage$ = this.errorMessageSubject.asObservable();
  filteredMaintenances$: Observable<Maintenance[]>;
  accounts$: Observable<Account[]>;
  solutions$: Observable<Solution[]>;
  maintenances$: Observable<Maintenance[]>;
  accountIds: string;
  solutionIds: string;
  folder: Solution;
  folderName: string;
  folderRegion: string;


  get accountFilter(): string {
    return this._accountFilter;
  }

  set accountFilter(value: string) {
    this._accountFilter = value;
    this.filteredMaintenances$ = this.filterMaintenancesWithAccount(value);
  }

  @Input()
  get removeIcon(): string { return this._removeIcon; }
  set removeIcon(value: string) {
    this._removeIcon = value;
  }
  _removeIcon = 'delete';

  @Input()
  get removeTooltip(): string { return this._removeTooltip; }
  set removeTooltip(value: string) {
    this._removeTooltip = value;
  }
  _removeTooltip = 'Delete Maintenance';

  constructor(
    private maintenanceService: MaintenanceService,
    private accountService: AccountService,
    private solutionService: SolutionsService) {}

  ngOnInit(): void {
    this.maintenances$ = this.maintenanceService.getMaintenances();
    this.maintenances$.subscribe((maintenances) => {
      this.accounts$ = this.accountService.queryAccounts(maintenances
            .map(maintenance => maintenance.accountId).toString());
      this.solutions$ = this.solutionService.querySolutions(maintenances
            .map(maintenance => maintenance.solutionId).toString());
      this.filteredMaintenances$ = this.prepareViewModel();
    });
  }

  private prepareViewModel() {
    return  combineLatest([ this.maintenances$, this.accounts$, this.solutions$ ]).pipe(
      map(([maintenances, accounts, solutions]) =>
        maintenances
        .filter((maintenance: Maintenance) => {
          this.folder = solutions.find((solution) => maintenance.solutionId === solution.id);
          this.folderName = (typeof this.folder !== 'undefined') ? this.folder.name : '';
          this.folderRegion = (typeof this.folder !== 'undefined') ? this.folder.region : '';
          return this.folderName !== '' && this.folderRegion !== '';
        })
        .map((maintenance: Maintenance) => {
          const activeMaintenanceWindow = this.getActiveMaintenanceWindow(maintenance);
          const account = accounts.find((account) => maintenance.accountId === account.id);
          const accountName = (typeof account !== 'undefined') ? account.name : '';
          this.folder = solutions.find((solution) => maintenance.solutionId === solution.id);
          this.folderName = (typeof this.folder !== 'undefined') ? this.folder.name : '';
          this.folderRegion = (typeof this.folder !== 'undefined') ? this.folder.region : '';
          return {
            ...maintenance,
            accountName: accountName,
            folderName: (maintenance.contextId === maintenance.accountId) ? '' : this.folderName,
            folderRegion: (maintenance.contextId === maintenance.accountId) ? '' : this.folderRegion,
            maintenanceStartTime: (activeMaintenanceWindow) ? activeMaintenanceWindow.startTimeUTC : '',
            maintenanceEndTime: (activeMaintenanceWindow) ? activeMaintenanceWindow.endTimeUTC : '',
            maintenanceNotes: (activeMaintenanceWindow) ? activeMaintenanceWindow.description : ''
          } as Maintenance;
        })
      )
    );
  }

  // Filter With Account Name
  private filterMaintenancesWithAccount(filterValue?: string): Observable<Maintenance[]> {
    return this.prepareViewModel()
    .pipe(
      map(maintenances =>
        maintenances.filter(maintenance =>
          maintenance.accountName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
        )
      )
    );
  }

  private getActiveMaintenanceWindow(maintenance: Maintenance) {
    if (maintenance !== null && maintenance.maintenanceWindows !== null) {
      return maintenance.maintenanceWindows.find((maintenanceWindow: MaintenanceWindow) => {
        if (maintenanceWindow.isArchived === false) {
          const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
          const currentDateTime = moment(new Date(), DATE_TIME_FORMAT);
          return (moment(currentDateTime) < moment(maintenanceWindow.endTimeUTC));
        }
        return false;
      });
    }
    return null;
  }

  removeMaintenance(solutionId: string) {
    this.maintenanceService.deleteMaintenance(solutionId).subscribe(() => {
      this.ngOnInit();
    });
  }

}
