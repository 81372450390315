import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CONFIGURATION, Configuration } from './configuration';

if (environment.production) {
  enableProdMode();
}

async function main() {
  try {
    const data = await fetch('assets/config/config.json', {
      headers: {
        'Cache-Control': 'no-cache, no-store',
        'Pragma': 'no-cache'
      }
    });
    const config = await data.json();

    const extraProviders = [
      {
        provide: CONFIGURATION,
        useValue: config as Configuration
      }
    ];

    await platformBrowserDynamic(extraProviders).bootstrapModule(AppModule);
  } catch (error) {
    console.error(error);
  }
}

main();
