import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate, Account, Solution } from '@aveva/connect-web-core';
import { combineLatest, Observable} from 'rxjs';
import * as moment from 'moment';
import { filter, map} from 'rxjs/operators';
import { Maintenance, MaintenanceWindow } from './maintenance';
import { MaintenanceService } from './maintenance.service';
import { ThemePalette } from '@angular/material/core';
import { AccountService } from 'src/app/shared/account.service';
import { SolutionsService } from 'src/app/shared/solutions.service';

@Component({
  selector: 'app-maintenance-edit',
  templateUrl: './maintenance-edit.component.html',
  styleUrls: ['./maintenance-edit.component.scss']
})
export class MaintenanceEditComponent implements OnInit, ComponentCanDeactivate {
  maintenance?: Maintenance;
  maintenanceWindow?: MaintenanceWindow;
  disabled = false;
  showSpinners = true;
  showSeconds = false;
  touchUi = false;
  enableMeridian = false;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  color: ThemePalette = 'primary';
  error: string;
  submitting = false;
  minDate: Date;
  maintenance$: Observable<Maintenance>;
  account$: Observable<Account>;
  solution$: Observable<Solution>;

  @ViewChild('form', {static: true}) form: NgForm;

  // @ViewChild('startTimePicker') startTimePicker: any;
  // @ViewChild('endTimePicker') endTimePicker: any;

  constructor(
    private maintenanceService: MaintenanceService,
    private accountService: AccountService,
    private solutionService: SolutionsService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.minDate = moment().toDate();
    const solutionid = this.route.snapshot.paramMap.get('solutionid');
    this.maintenance$ = this.maintenanceService.getMaintenance(solutionid);
    this.maintenance$.subscribe(maintenance => {
      this.account$ = this.accountService.getAccount(maintenance.accountId);
      this.solution$ = this.solutionService.getSolution(maintenance.solutionId);
      this.prepareViewModel().subscribe(data => { this.maintenance = data; });
    });
  }

  private prepareViewModel() {
    return combineLatest(
      [ this.maintenance$, this.account$, this.solution$]).pipe(
        filter(([maintenance]) => Boolean(maintenance)),
        map(([maintenance, account, solution]) => {
          const activeMaintenanceWindow = this.getActiveMaintenanceWindow(maintenance);
          return {
            ...maintenance,
            accountName: account.name,
            folderName: (maintenance.accountId !== maintenance.contextId) ? solution.name : '',
            folderRegion: (maintenance.accountId !== maintenance.contextId) ? solution.region : '',
            maintenanceStartTime: (activeMaintenanceWindow) ? activeMaintenanceWindow.startTimeUTC : '',
            maintenanceEndTime: (activeMaintenanceWindow) ? activeMaintenanceWindow.endTimeUTC : '',
            maintenanceNotes: (activeMaintenanceWindow) ? activeMaintenanceWindow.description : ''
          };
        }));
  }

  private getActiveMaintenanceWindow(maintenance: Maintenance) {
    if (maintenance !== null && maintenance.maintenanceWindows !== null) {
      return maintenance.maintenanceWindows.find((maintenanceWindow: MaintenanceWindow) => {
        if (maintenanceWindow.isArchived === false) {
          const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
          const currentDateTime = moment(new Date(), DATE_TIME_FORMAT);
          return (moment(currentDateTime) < moment(maintenanceWindow.endTimeUTC));
        }
        return false;
      });
    }
    return null;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.form.dirty || this.form.submitted;
  }

  isMaintenanceWindowValid() {
    const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
    const currentDateTime = moment(new Date(), DATE_TIME_FORMAT);

    return ( (this.maintenance !== undefined) &&
      (moment(currentDateTime) < moment(this.maintenance.maintenanceEndTime)) &&
      (moment(this.maintenance.maintenanceStartTime) !== moment(this.maintenance.maintenanceEndTime)) &&
      (moment(this.maintenance.maintenanceStartTime) < moment(this.maintenance.maintenanceEndTime)) &&
      this.maintenance.maintenanceNotes !== '');
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }
    this.minDate = moment(this.maintenance.maintenanceStartTime).toDate();
    this.submitting = true;
    this.maintenanceWindow = {
      'startTimeUTC': this.maintenance.maintenanceStartTime,
      'endTimeUTC': this.maintenance.maintenanceEndTime,
      'isArchived': false,
      'description': this.maintenance.maintenanceNotes
    };
    const obs = this.maintenanceService.updateMaintenance(this.maintenance.solutionId, this.maintenanceWindow);
    obs.subscribe(() => {
      this.submitting = false;
      this.router.navigate(['/maintenance'], { relativeTo: this.route });
    });
  }

}
