import { InjectionToken } from '@angular/core';

export const CONFIGURATION = new InjectionToken<Configuration>('cloudue-management-configuration');

export interface Configuration {
  clientId: string;
  authority: string;
  apis: {
    am: string;
    uam: string;
    scm: string,
    logging: string;
    maintenance: string
  };
}
