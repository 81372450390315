import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorizationService } from '@aveva/connect-web-core';
import { Observable } from 'rxjs';

@Injectable()
export class ConnectAdminGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot, //eslint-disable-line  @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree { //eslint-disable-line  @typescript-eslint/no-unused-vars
    return this.authorizationService.isConnectAdmin(true);
  }
}
