import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Account } from '@aveva/connect-web-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators'; //eslint-disable-line  @typescript-eslint/no-unused-vars
import { Configuration, CONFIGURATION } from 'src/configuration';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private cache$: Observable<Account[]>;

  constructor(
    private http: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
  ) { }

  queryAccounts(accountIds: string) {
    if (!this.cache$) {
      this.cache$ = this.getAccounts(accountIds).pipe(
        shareReplay(1)
      );
    }
    return this.cache$;
  }

  private getAccounts(accountIds: string) {
    const params = new HttpParams().set('ids', accountIds);
    return this.http.get<Account[]>(`${this.configuration.apis.am}/accounts/`, { params: params })
      .pipe(
        map((data: any) => data._embedded['doc:accounts']), //eslint-disable-line  @typescript-eslint/no-explicit-any
        catchError(this.handleError)
      );
  }

  getAccount(accountId: string) {
    return this.http.get<Account>(`${this.configuration.apis.am}/accounts/${accountId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(`Error: ${error.message}`);
    } else {
      // Server-side errors
      return throwError(`Error: Backend returned code ${error.status}: ${error.message}`);
    }
  }
}
