import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PostLoginRedirectService, AuthenticationService, AuthenticatedUser, ErrorHandlerService, SideNavLink } from '@aveva/connect-web-core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  links: SideNavLink[] = [{
    label: 'Maintenance',
    iconLigature: 'maintenance',
    routerLink: ['./maintenance']
  }];

  constructor(
    private translate: TranslateService,
    private postLoginRedirect: PostLoginRedirectService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) {
    this.translate.setDefaultLang('en');
    this.postLoginRedirect.watch();
    this.authenticationService.registerCallbackHandler(
      user => this.onAuthenticationCallbackSuccess(user),
      error => this.onAuthenticationCallbackError(error));
  }

  private async onAuthenticationCallbackSuccess(user: AuthenticatedUser) {
    if (user.isSystemUser) {
      this.router.navigate(['/maintenance'], { replaceUrl: true });
    } else {
      try {
        await this.errorHandler.handle(
          'callback.controller: Could not retrieve the user.account property',
          'accountDetailsNotAvailable');
      } catch {}
      await this.authenticationService.signout();
    }
  }

  private async onAuthenticationCallbackError(error: Error) {
    try {
      await this.errorHandler.handle(error, 'signin_failed');
    } catch {}
    await this.authenticationService.signout();
  }
}
