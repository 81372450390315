import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Solution } from '@aveva/connect-web-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Configuration, CONFIGURATION } from 'src/configuration';

@Injectable({
  providedIn: 'root'
})
export class SolutionsService {
  private cache$: Observable<Solution[]>; 
  constructor(
    private http: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
  ) { }

  querySolutions(solutionIds: string) {
    if (!this.cache$) {
      this.cache$ = this.getSolutions(solutionIds).pipe(
        shareReplay(1)
      );
    }
    return this.cache$;
  }

  private getSolutions(solutionIds: string) {
    const params = new HttpParams().set('ids', solutionIds);
    return this.http.get<Solution[]>(`${this.configuration.apis.scm}/solutions/`, { params: params })
      .pipe(
        map((data: any) => data._embedded['doc:solutions']), //eslint-disable-line  @typescript-eslint/no-explicit-any
        catchError(this.handleError)
      );
  }

  getSolution(solutionId: string) {
    return this.http.get<Solution>(`${this.configuration.apis.scm}/solutions/${solutionId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(`Error: ${error.message}`);
    } else {
      // Server-side errors
      return throwError(`Error: Backend returned code ${error.status}: ${error.message}`);
    }
  }
}
