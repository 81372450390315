import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CONFIGURATION, Configuration } from 'src/configuration';
import { Maintenance, MaintenanceWindow } from './maintenance';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  constructor(
    private http: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
  ) { }

  // Get all maintenances
  getMaintenances() {
    return this.http.get<Maintenance[]>(`${this.configuration.apis.maintenance}/maintenance`)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get maintenance by Solution Id
  getMaintenance(solutionId: string) {
    return this.http.get<Maintenance>(`${this.configuration.apis.maintenance}/maintenance/${solutionId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Update maintenance
  updateMaintenance(solutionId: string, payload: MaintenanceWindow) {
    return this.http.put(`${this.configuration.apis.maintenance}/maintenance/${solutionId}`, payload)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Delete maintenance-set archived
  deleteMaintenance(solutionId: string) {
    return this.http.delete<Maintenance>(`${this.configuration.apis.maintenance}/maintenance/${solutionId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any): Observable<never> { //eslint-disable-line  @typescript-eslint/no-explicit-any
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(`Error: ${error.message}`);
    } else {
      // Server-side errors
      return throwError(`Error: Backend returned code ${error.status}: ${error.message}`);
    }
  }
}
